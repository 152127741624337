import React from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import * as Text from "@/components/text";

import Button from "@/components/actions/Button";

export default function ExploreMovies({
  images = [],
  title = "Más de 700 películas para despertar tu consciencia",
  subtitle = "Descrubre por temáticas, años, países y más",
  button_label = "Explora el catálogo",
  button_link = "/",
}) {
  return (
    <div className="relative w-full">
      <motion.div
        // initial={{ opacity: 0, scale: 0.2 }}
        // animate={{ opacity: 1, scale: 1 }}
        // transition={{
        //   duration: 2.0,
        //   delay: 0.5,
        //   ease: [0, 0.71, 0.2, 1.01],
        // }}
        className="flex max-w-full justify-center overflow-hidden"
      >
        <div className="grid min-w-[920px] grid-cols-7 gap-2 text-center">
          <div className="flex flex-col justify-center ">
            <img
              className="pointer-events-none  -mt-14  aspect-[2/3] object-cover "
              src={images[0]}
            ></img>
          </div>
          <div className="flex flex-col justify-center">
            <img
              alt="Explore Movies"
              className="pointer-events-none  mt-20 aspect-[2/3] object-cover "
              src={images[1]}
            ></img>
          </div>
          <div className="flex flex-col justify-center space-y-3">
            <img
              className="pointer-events-none aspect-[2/3] object-cover "
              src={images[2]}
            ></img>
            <img
              className="pointer-events-none aspect-[2/3] object-cover "
              src={images[3]}
            ></img>
          </div>
          <div className="flex flex-col justify-center space-y-3">
            <img
              className="pointer-events-none aspect-[2/3] object-cover"
              src={images[4]}
            ></img>
            <img
              className="pointer-events-none aspect-[2/3] object-cover"
              src={images[5]}
            ></img>
            <img
              className="pointer-events-none aspect-[2/3] object-cover"
              src={images[6]}
            ></img>
          </div>
          <div className="flex flex-col justify-center space-y-3">
            <img
              className="pointer-events-none -mt-20 aspect-[2/3] object-cover"
              src={images[7]}
            ></img>
            <img
              className="pointer-events-none aspect-[2/3] object-cover"
              src={images[8]}
            ></img>
          </div>
          <div className="flex flex-col justify-center space-y-3">
            <img
              className="pointer-events-none aspect-[2/3] object-cover "
              src={images[9]}
            ></img>
          </div>
          <div className="flex flex-col justify-center space-y-3">
            <img
              className="pointer-events-none -mt-20 aspect-[2/3] object-cover "
              src={images[10]}
            ></img>
          </div>
        </div>
      </motion.div>
      <motion.div
        // initial={{ opacity: 0, scale: 0.5 }}
        // animate={{ opacity: 1, scale: 1 }}
        // transition={{
        //   duration: 2.0,
        //   delay: 0.5,
        //   ease: [0, 0.71, 0.2, 1.01],
        // }}
        className="absolute left-0 top-0  h-full w-full"
      >
        <div className="flex h-full items-center justify-center align-middle ">
          <div className="space-y-5 bg-black p-5 px-5 text-center text-white">
            <div className="flex max-w-screen-sm flex-wrap justify-center space-y-2">
              <Text.Headline size="sm">{title}</Text.Headline>
              <Text.Body
                className="text-center font-light text-neutral-200"
                size="md"
                fontWeight="light"
              >
                {subtitle}
              </Text.Body>
            </div>
            <Link href={button_link}>
              <Button
                size="md"
                color="secondary-dark"
                className="m-auto mt-2 w-full md:mt-5 md:w-auto"
                label={button_label}
              />
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
